// first palette for Pure Casino
$bg-color: #020e08;
$text-color: #e2e2e5;
$primary: #08d5a3;
$success: #00b894;
$info: #d2b475;
$danger: #ff1744;


//hamburger-menu in primary color
$navbar-dark-icon-color: $primary;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-icon-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");

$gray-700: mix($bg-color, #fff, 80%); //bs-border-color


#rivalHeaderNavAndLogoSection img {
  margin-top: -10px;
  margin-bottom: -10px;
  max-width: 200px !important;
}


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;


//menu in primary color
.sticky-sidebar {
  .nav-link:hover, .active-link {
    color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }
  //TODO language dropdown stay hover color = primary when opened area-expanded true
  .dropdown-item:active {
    background-color: $primary !important;
  }
}


#category-nav li button {
  &:hover, &.active {
    --bs-nav-pills-link-active-color: #{$bg-color} !important;
  }
}



#promos {
  img {
    width: 150% !important;
  }
}

